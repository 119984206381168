.card.flip {
    --rotate-y: 180deg
}



.card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 15;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 0.2);
    transform-style: preserve-3d;
    transition: 600ms;
    transform: perspective(2000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
    width: 325;
    height: 325;
    margin: 10px;

}

.card:hover {
    --translate-y: -2px;
    opacity: 1;
}

.card .back {
    transform: rotateY(180deg)
}

.addressHeaderContainer {
    width: 100%;
    padding: 4px;
}

.addressHeader {
    color: white;
    text-align: center;
    padding-top: 15;
}

.screenContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 60px;
}

.image {
    width: 600px;
    border-radius: 20px;
}