.header {
    flex-direction: row;
    display: flex;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 0.2);
    background-color: lightgray;
    opacity: 0.7;
    position: fixed;
    width: 100vw
}

.backgroundImage {
    background-image: url(../../assets/firstimage.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.headerLogoContainer {
    display: flex;
    margin-right: 60;
}

.toolbarButtonsContainer {
    margin-left: 50;
    margin-top: 5;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    overflow-x: hidden;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    flex-wrap: wrap;
}

/* .card {
    width: 400px;
    height: 400px;
    background-color: white;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 0.2);
    border-radius: 15px;
    margin: 15px;

} */

.innerCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 400px;
    width: 400px;
}

.sold {
    font-weight: 500;
    color: red;
    background-color: pink;
    padding: 5px;
    border-radius: 10px;
    width: 50px;
    text-align: center;
    margin-left: 15px;
}