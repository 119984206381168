.header {
    flex-direction: row;
    display: flex;
    flex: 1;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 0.2);
    background-color: lightgray;
    opacity: 0.7;
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.headerLogoContainer {
    display: flex;
    margin-right: 60;
}

.toolbarButtonsContainer {
    margin-left: 50;
    margin-top: 5;
}