.header {
    flex-direction: row;
    display: flex;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 0.2);
    background-color: lightgray;
    opacity: 0.7;
    position: fixed;
    width: 100vw
}

.backgroundImage {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.headerLogoContainer {
    display: flex;
    margin-right: 60;
}

.toolbarButtonsContainer {
    margin-left: 50;
    margin-top: 5;
}

.contentContainer1 {
    margin: 50;
    display: flex;
    width: 65%;
    background-color: white;
    border-radius: 20;
    padding: 15;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.buyAndSellContainer {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    width: 30%;
    align-items: center;
    justify-content: center;
}

.innerSwiper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
}

.swiperBody {
    text-align: center;
    margin-bottom: 50px
}

.swiperHeaderContainer {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.swiperName {
    font-weight: bold;
    margin-right: 7px;
    margin-bottom: 17px;
    margin-left: 10px;
}

.reviewName {
    font-weight: bold;
    font-size: 15px;
}

.ratingContainer {
    margin-top: 12px;
    margin-left: 4px;
}

.innerReview {
    display: flex; 
    flex-direction: column
}

.reviewHeading {
    display: flex;
    flex-direction: row;
}

.reviewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reviewCard {
    background-color: white;
    width: 300px;
    height: 160px;
    padding: 15px;
    margin-bottom: 15px;
    overflow-y: auto;
    border-radius: 15px;
}

.vibrantText {
    color: #00b7ff; 
    font-style: italic;
}

.bulletPoint {
    font-weight: 500;
    margin-bottom: 10px;
}

.quoteContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.roundedHouse {
    width: 200px;
    height: 200px;
    border-radius: 300px;
    margin: 15px;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 0.2);
}

.contentContainer2 {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}

.paragraph {
    font-weight: 500; 
    font-size: 17px, 
}

.paragraphContainer {
    display: flex;
    flex-direction: column;
}

.contentRow1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.contentRow2 {
    display: flex;
    justify-content: center;
    background-color: #f1f1f1;
}

.contentContainer5 {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    border-radius: 20px;
    padding: 15px;
    justify-content: center;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 0.2);
}

.innerContentContainer2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swiper {
width: 50%;
height: 82%;
background-color: #f1f1f1;
border-radius: 15px;
box-shadow: 5px 5px 10px rgb(0 0 0 / 0.2);
}

.buySellButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.paragraphTitle {
    font-weight: bold;
    color: #2076d1;
    font-size: 27px;
}

.tipsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contentContainer6and7 {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    height: 260px;
    background-color: white;
    border-radius: 20px;
    padding: 15px;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 0.2);
}

.websiteContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.websiteRow {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentContainer3 {
    display: flex;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 0.2);
}

