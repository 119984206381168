.border:before{
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	border-bottom: solid 3px;
	animation: border_anim 250ms linear forwards;
}

@keyframes border_anim {
	0%{
		width: 0%;
	}
	100%{
		width: 100%;
	}
}